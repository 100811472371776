import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  background-image: linear-gradient(to bottom, #679898, #f0f5f5);
`

const SectionProductGuide = () => (
  <>
    <StyledDiv className="py-20">
      <div className="w-11/12 lg:w-10/12 max-w-screen-lg mx-auto">
        <p className="text-white text-lg md:text-xl text-center md:px-12">At SRA we believe small and mid-size businesses are the lifeblood of the American economy. The 831(b) and Safe Harbor Plans we administrate are meant to ensure these businesses weather the storm. We care deeply about the financial security of our clients and their businesses. Our talented and motivated team strives to provide creative, quality, well-designed plans to address the varying needs of the clients we serve.</p>
        <p className="text-white text-lg md:text-xl text-center md:px-12 mt-6">You can learn more about our 831(b) Plan and Safe Harbor Plans in our Product Guide</p>
      </div>

      <div className="w-11/12 max-w-screen-xl mx-auto">
        <iframe title="SRA Product Guide" src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=xplwkk7y6z" width="100%" height="480" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen />
      </div>
    </StyledDiv>
  </>
)

export default SectionProductGuide